.prenavbar {
    display: none;
    text-align: right;
    justify-content: flex-end;
    align-items: center;
    font-weight: 200;
    color: #fff;
    padding: 1rem 0;

    @include media-breakpoint-up(lg){
        display: flex;
    }
}

.languages {
    display: inline-block;
    margin: 0;
    padding: 0;

    .lang-item {
        font-weight: 400;
        list-style: none;
        display: inline-block;
        text-transform: uppercase;
        margin: 0 .5rem 0 0;
    }
}
section {
    margin: 1rem 0;
}

.section {
    margin: 2.5rem 0;
    
    @include media-breakpoint-up(sm){
        margin: 4rem 0;
    }

    &__title {
        font-size: 1.5rem;
    }
}
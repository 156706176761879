.contact {
    a {
        color: $theme-color-red;
    }

    .acf-map {
        border: 1px solid #A7A7A7;
        min-height: 300px;
    }
}

.email-form {
    position: relative;
    margin-top: 2rem;
    padding: 3rem 0;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 580px;
        @include fillit(5,5,f,f,f);
        border-bottom-right-radius: 500px;
        background: $theme-color-gray-light;
        z-index: -1;

        @include media-breakpoint-up(lg){
            height: 480px;
        }
    }

    .btn--send {
        min-width: 150px;

        &:hover {
            color: #fff;
        }
    }
}
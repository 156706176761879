.home-offer {
    position: relative;
    z-index: 10;
    
    @include media-breakpoint-up(md){
        margin-top: -3rem;
    }
}

.info-box {
    position: relative;
    color: #fff;
    border-top-left-radius: 100px;
    padding: 2rem 2.5rem;
    height: 100%;

    h2 {
        font-size: 1.75rem;
        text-align: center;
        margin-bottom: 1.5rem;
    }

    a {
        position: absolute;
        font-weight: 500;
        bottom: -1.2rem;
        right: 0;
        padding: .6rem 1rem .6rem 2rem;
        border-top-left-radius: 100px;
        color: $theme-color-black;
        background-color: #fff;
        overflow: hidden;
        z-index: 9;
        transition: background-color .3s cubic-bezier(0.55, 0.085, 0.68, 0.53), color .3s ease-in-out;
         
        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            transform: translate(-55%, 105%);
            width: 140%;
            height: 360%;
            border-radius: 50%;
            transition: transform .7s cubic-bezier(0.165, 0.84, 0.44, 1);
            z-index: -1;
        }


    }

    &--red {
        background: $theme-color-red;
        
        a {
            &::before {
                background: $theme-color-black;
            }
        }

        &:hover {
            a {
                color: #fff;
            }
        }
    }

    &--black {
        background: $theme-color-black;

        a {
            &::before {
                background-color: $theme-color-gray-light;
            }
        }
    }

    &--gray {
        color: $theme-color-black;
        background: $theme-color-gray-light;

        a {
            &::before {
                background-color: $theme-color-red;
            }
        }

        &:hover {
            a {
                color: #fff;
            }
        }
    }

    &:hover {
        a {
            &::before {
                transform: translate(-15%,50%);
            }
        }
    }
}

.counters {
    &__img {
        display: none;
        width: 100%;
        height: 150px;
        border-top-right-radius: 500px;
        background: url('./../img/content/counters_img.png') no-repeat top/cover;
        filter: brightness(0.7);

        @include media-breakpoint-up(md){
            display: block;
        }
    }

    &__content {
        position: relative;
        
        @include media-breakpoint-up(md){
            margin-top: -3rem;
        }
    }

    &__item {
        background-color: $theme-color-red;
        border-top-left-radius: 100px;
        border-bottom-right-radius: 100px;
        color: #fff;
        display: flex;
        line-height: 1.2;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 150px;
        margin-bottom: 2rem;
        transition: box-shadow .3s cubic-bezier(0.455, 0.03, 0.515, 0.955), background-color .4s ease-in-out;

        @include media-breakpoint-up(lg){
            margin-bottom: 0;
        }

        &:hover {
            box-shadow: 4px 2px 10px rgba(0, 0, 0, 0.35);
        }

        .value {
            font-size: 2.25rem;
            font-weight: 500;
            
        }
        
        .name {
            font-size: 1.25rem;

            @include media-breakpoint-up(xl){
                font-size: 1.5rem;
            }
        }
    }
}

.catalog {
    position: relative;
    padding: 1rem 0 3rem;
    margin-bottom: 3rem;
    min-height: 450px;
    background: $theme-color-gray-light;

    @include media-breakpoint-up(lg){
        background: none;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        background: $theme-color-gray-light;
        height: 100%;
        min-height: 730px;
        @include fillit(6,6,f,f,f);
        display: none;
        z-index: -1;
        
        @include media-breakpoint-up(lg){
            display: block;
            min-height: 620px;
            border-bottom-right-radius: 500px;
            padding: 0;
            top: -10rem;
            height: 90%;
        }
    }
}
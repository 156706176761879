.news {
    position: relative;
    width: 100%;
    max-width: 420px;
    border-radius: 100px 0;
    margin: 0 auto;
    
    &__image {
        position: relative;
        border-radius: 100px 0;
        width: 100%;
        height: 300px;
        z-index: 9;
        overflow: hidden;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.13);

        img {
            position: absolute;
            object-position: center;
            object-fit: cover;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
        }

        .date {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: .6rem 1.5rem;
            border-top-right-radius: 20px;
            background-color: $theme-color-black;
            color: #fff;
        }
    }

    &__title {
        margin-top: .5rem;
        font-size: 1rem;
        font-weight: 600;
    }
}
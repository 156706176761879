.navbar {
    position: relative;
    padding: 0;
    @include fillit(12,12,f,f,f);
    text-transform: uppercase;
    font-weight: 600;
    margin-left: auto;
    background: #fff;
    right: 0;
    z-index: 9;
    max-width: 100%;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        background: $theme-color-red;
        z-index: -1;

        @include media-breakpoint-up(lg){
            width: calc(((100vw - 960px)/ 2) + ((960px * (2 / 12))) - 20px);
        }

        @include media-breakpoint-up(xl){
            width: calc(((100vw - 1140px)/ 2) + ((1140px * (2 / 12))) - 20px);
        }
    }

    @include media-breakpoint-up(lg){
        border-top-left-radius: 100px;
        padding-left: 2.5rem;
    }

    &__container {
        @include make-container();
        
        display: flex;
        justify-content: space-between;
        max-width: 100%;
        padding: 0;

        @include media-breakpoint-up(lg){
            justify-content: start;
            margin-left: 0;
            padding-left: 15px;
            padding-right: 15px;
            max-width: 960px;
        }

        @include media-breakpoint-up(xl){
            max-width: 1140px;
        }
    }

    &__logo {
        order: 0;
        padding: .5rem 1rem;
        
        @include media-breakpoint-up(lg){
            padding: .5rem 0;
            @include make-col(2);
        }
    }

    &__menu {
        display: flex;
        align-items: center;
        order: 2;
        text-align: center;
        padding: 0 1rem;
        width: 100%;

        @include media-breakpoint-down(md){
            position: absolute;
            background: #fff;
            top: 86px;
            box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.3);
        }
        
        @include media-breakpoint-up(lg){
            order: 1;
            text-align: left;
            @include make-col(8);
        }

        .navbar-nav {
            justify-content: space-between;
            width: 100%;

            .nav-item {
                transition: color .2s ease-in-out;

                &:hover {
                    color: $theme-color-red;
                }

                .nav-link {
                    @include media-breakpoint-only(lg){
                        padding-left: .2rem;
                        padding-right: .2rem;
                    }
                }
            }
        }
    }

    &__catalog {
        display: none;
        order: 3;
        justify-content: center;
        align-items: center;
        color: #fff;        
        padding: 0 1rem;
        
        @include media-breakpoint-up(lg){
            display: flex;
            @include make-col(2);
        }
    }

    &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        order: 1;
        background: $theme-color-red;
        padding: 1rem 2rem;

        path {
            fill: #fff;
        }

        @include media-breakpoint-up(lg){
            order: 2;
            display: none;
        }
    }
}
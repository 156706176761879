.text--red {
    color: $theme-color-red;
}

.img--rounded {
    border-top-left-radius: 100px;
    border-bottom-right-radius: 100px;
}

.h1--small {
    @include media-breakpoint-down(xs){
        font-size: 1.75rem;
    }
    @include media-breakpoint-only(sm){
        font-size: 2rem;
    }
}

.font--small {
    font-size: .9rem;
}
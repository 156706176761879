.btn {
    position: relative;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 500;
    border-bottom-left-radius: 100px;
    padding: .8rem 1.2rem .8rem 2.4rem;
    border: 2px solid transparent;
    max-height: 50px;
    overflow: hidden;
    color: #fff;
    transition: all .3s ease-in-out;
    z-index: 9;
    
    path {
        transition: fill .3s ease-in-out;
    }
    
    &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translate(-55%, 105%);
        width: 140%;
        height: 360%;
        border-radius: 50%;
        transition: transform .7s cubic-bezier(0.165, 0.84, 0.44, 1);
        z-index: -1;
    }

    &:active,
    &:focus {
        outline: none;
        box-shadow: none;
    }

    &:hover {
        &:before {
            transform: translate(-15%,50%);
        }
    }

    .icon {
        display: inline-flex;
        margin-left: .6rem;
    }

    &--white {
        background: #fff;
        color: $theme-color-black;
        border-color: #fff;
        
        &::before {
            background: $theme-color-red;
        }

        &:hover {
            color: #fff;
            border-color: $theme-color-red;
            
            path {
                fill: #fff;
            }
        }
    }
    
    &--gray {
        background: $theme-color-black;
        
        &::before {
            background: #fff;
        }

        &:hover {
            color: $theme-color-black;
            
            path {
                fill: $theme-color-black;
            }
        }
    }
    
    &--red {
        background: $theme-color-red;

        &::before {
            background: #fff;
        }

        &:hover {
            color: $theme-color-red;

            path {
                fill: $theme-color-red;
            }
        }
    }
}
footer {
    margin: 3rem 0 2rem;
    text-align: right;

    @include media-breakpoint-up(md){
        text-align: left;
    }

    .footer__lines {
        position: relative;
        display: block;
        width: 100%;
        height: 50px;
        border-top-right-radius: 500px;
        background: $theme-color-black;
        padding-top: 1rem;
        margin-bottom: 2.5rem;
        
        &::before {
            content: '';
            position: absolute;
            top: -1rem;
            left: 0;
            width: 96%;
            height: 100%;
            background: $theme-color-red;
            border-top-right-radius: 500px;
            z-index: -1;
        }
    }
    
    .home & {
        .footer__lines {
            display: none;
        }
    }
}
.pagination {
    margin-top: 1rem;

    .page-item {
        margin-right: 1.2rem;

        .page-link {
            border-radius: 0;
            padding: .5rem .85rem;
            background-color: $theme-color-black;
            border-color: $theme-color-black;
            color: #fff;
            
            &.current {
                background-color: #fff;
                border-color: $theme-color-black;
                color: $theme-color-black;
            }
        }
    }
}
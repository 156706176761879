.hero {
    position: relative;
    display: flex;
    align-items: flex-end;
    top: 0;
    left: 0;
    background: url('./../img/content/hero.png') no-repeat center/cover;
    border-bottom-right-radius: 250px;
    width: 100%;
    height: 340px;
    z-index: 3;
    margin-bottom: 1.5rem;
    
    @include media-breakpoint-up(md){
        height: 750px;
        margin-bottom: 0;
        border-bottom-right-radius: 500px;
    }

    &__body {
        position: relative;
        display: none;
        bottom: 0;
        height: 480px;
        width: 100%;

        @include media-breakpoint-up(md){
            display: block;
        }

        h1 {
            font-size: 2.25rem;
            font-weight: 300;
            text-transform: uppercase;
        }

        &::before {
            content: '';
            position: absolute;
            display: none;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 60vw;
            background: rgba(255, 255, 255, 0.15);
            border-top-right-radius: 500px;
            z-index: -1;
            
            @include media-breakpoint-up(md){
                display: block;
            }
            @include media-breakpoint-up(lg){
                width: 55vw;
            }
        }
    }

}
.breadcrumb {
    display: inline-flex;
    margin: 1rem 0;
    padding: 0;
    list-style: none;
    color: $theme-color-black;
    
    li {
        position: relative;
        font-size: 1rem;
        font-weight: 300;
        padding: .4rem 1rem;
        
        &:first-child {
            padding: .4rem 1rem .4rem 0;
        }

        &:not(:last-child){
            &:before {
                content: '|';
                position: absolute;
                right: 0;
                width: 1px;
                height: 100%;
            }
        }
    }
}
.box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 300px;
    max-width: 420px;
    border-radius: 100px 0;
    margin: 0 auto 3rem;
    overflow: hidden;
    color: #fff;
    transition: box-shadow .3s ease-in-out, border-radius .3s ease-in-out ;
    
    &:hover {
        border-radius: 0 100px;
        box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.44);
    }

    img:not(.icon) {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transition: transform .4s cubic-bezier(0.785, 0.235, 0.45, 1);
        z-index: 0;
    }        

    &__title {
        font-size: 1.8rem;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
        color: inherit;
        line-height: 1;
        z-index: 9;
        transition: opacity .25s ease-in-out;
        
        @include media-breakpoint-up(md){
            font-size: 2.25rem;
            
            &--small {
                font-size: 2rem;
            }
        }

        span {
            display: block;
            visibility: hidden;
            opacity: 0;
            font-size: 0.9rem;
            margin-top: 1rem;
            transition: all .3s ease-in-out;
        }
    }

    &__subtitle {
        font-size: 1.2rem;
        font-weight: 400;
        color: inherit;
        z-index: 9;
        
        @include media-breakpoint-up(md){
            font-size: 1.5rem;
        }
    }

    &__number {
        position: absolute;
        top: 20px;
        right: 5px;
        font-size: 140px;
        font-weight: 600;
        line-height: 140px;
        color: rgba(255, 255, 255, 0.15);
        transition: opacity .25s ease-in-out;
    }

    &__icon {
        display: flex;
        justify-content: center;
        width: 80px;
        height: 80px;
        text-align: center;
        margin-bottom: .8rem;
        transition: opacity .25s ease-in-out;
    }

    &--red {
        background-color: rgba(215, 24, 42, 1);
        
        img:not(.icon) {
            background-color: rgba(215, 24, 42, 0.8);
            mix-blend-mode: multiply;
        }
    }

    &--black {
        background-color: rgba(61, 61, 61, 0.7);
        
        img {
            background-color: rgba(61, 61, 61, 0.8);
            mix-blend-mode: multiply;
        }
    }

    &--flip {
        cursor: pointer;
        transition: background-color .3s cubic-bezier(0.39, 0.575, 0.565, 1);
        background-color:  $theme-color-red;
        
        img:not(.icon) {
            background-color: rgba(215, 24, 42, 0.8);
            mix-blend-mode: multiply;
        }

        .box__back {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            line-height: 1.4;
            width: 100%;
            height: 100%;
            padding: 1.8rem;
            opacity: 0;
            transition: opacity .3s cubic-bezier(0.86, 0, 0.07, 1);
            transition-delay: 0s;
        } 

        .box__title {
            line-height: 1.3;
        }

        .box__number,
        .box__title,
        .box__icon {
            transition-delay: .15s;
        }

        &.active {
            background-color: $theme-color-black;

            .box__number,
            .box__title,
            .box__icon {
                opacity: 0;
                transition-delay: 0s;
            }

            .box__back {
                opacity: 1;
                transition-delay: .45s;
            }
        }

        &:hover {
            border-radius: 100px 0;
            box-shadow: none;

            .box__title span {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}
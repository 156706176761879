.pwa-box {
    position: fixed;
    top: -90px;
    right: 10px;
    z-index: 100000;
    max-width: 500px;
	text-align: center;
	transition: all 1s ease;
    box-shadow: 0px 2px 5px rgba($color: #000000, $alpha: .2);
    display: none;

    @include media-breakpoint-up(lg) {
        display: block;
    }

	&.show {
		top: 10px;
    }
        
    &__body {
        padding: 8px;
        display: flex;
        background-color: #fff;
        align-items: center;
        flex-direction: row;
        border-radius: 4px 4px 0 0;

        img {
            max-width: 60px;
            max-height: 35px;
            margin-right: .8rem;
        }
    }

    &__text {
        font-size: .9rem;
    }

    &__button {
        width: 100%;
        padding: .4rem;
        background: #3d3d3d;
        color: #fff;
        border-radius: 0 0 4px 4px;
    }

    .close {
        cursor: pointer;
        position: relative;
        margin-left: .7rem;
        width: 15px;
        height: 15px;
        
        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 100%;
            max-width: 15px;
            height: 1px;
            background-color: #000;
            transform: rotate(45deg);
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            max-width: 15px;
            height: 1px;
            background-color: #000;
            transform: rotate(-45deg);
        }
        
    }
}

.firefox-pwa-tooltip {
    display: block;
    top: auto;
    border-radius: 4px;
    max-width: calc(100% - 20px);

    &:not(.show){
        bottom: -90px;
    }

    &.show {
        top: auto;
        bottom: 10px;
    }

    .mobile{     
        background: #fff;
        border-radius: 4px;

        span {
            position: relative;
            display: inline-flex;
            top: -4px;
            width: 5px;
            height: 5px;
            border-radius: 100%;
            background: #3d3d3d;
            margin: 0 .3rem;

            &::before,
            &::after {
                content: '';
                position: absolute;
                width: 5px;
                height: 5px;
                border-radius: 100%;
                background: #3d3d3d;
            }

            &::before {
                top: -7px;
            }

            &::after {
                top: 7px;
            }
        }

        .close {
            background: #3d3d3d;
            width: 35px;
            height: auto;
            border-radius: 0 4px 4px 0;
            padding: 0 1rem;

            &::before,
            &::after {
                background: #fff;
            }
        }
        
    }

    .desktop {            
        padding-bottom: .5rem;
        width: 400px;
        background: #3d3d3d;
        color: #fff;

        .screenshot {
            height: 180px;
            width: 100%;
            background-image: url('./../img/content/firefox-pwa.png');
            background-repeat: no-repeat;
            background-position: right;
            background-color: #fff;
        }

        .pwa-box__text {
            padding: .3rem .8rem;
            line-height: 1.4;
        }

        .close {
            margin-right: .7rem;

            &::before,
            &::after {
                background: #fff;
            }
        }
    }

    @include media-breakpoint-up(lg) {            
        &:not(.show){
            top: -270px;
            bottom: auto;
        }
        &.show {
            top: 10px;
            bottom: auto;
        }
    }
}

#offline-mode{
    display: none;
    width: 100%;
    height: 100%;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px; 
    z-index: 1000000;
    position: fixed;
    background-color: #fff;

    &.show{
        display: block;
    }
    h2, h3{
        max-width: 600px;
    }
    .inner{
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.mobile-pwa-tooltip {

	&.show {
		opacity: 1;
	}
	.close {
        cursor: pointer;
		position: absolute;
		right: 3px;
		top: 3px;
		z-index: 11;
		font-size: 0.8em;
	}

	.arrow {
		z-index: 10;

		&::before,
		&::after {
			content: "";
			position: absolute;
			top: 0;
			border-width: 0.5rem 0.5rem 0;
			border-color: transparent;
			border-style: solid;
			z-index: 10;
			border-top-color: rgba($color: #000000, $alpha: 0.2);
        }
        
		&::after {
			margin-top: -1px;
			border-top-color: $white;
		}
	}

	.tooltip-inner {
		max-width: 340px;
		padding: 0.25rem 0.5rem;
		color: $dark;
		border: 1px solid rgba($color: #000000, $alpha: 0.2);
		text-align: center;
		font-size: 12px;
		background-color: $white;
		border-radius: 0;
		font-weight: 300;
		padding: 10px;
		position: relative;
        box-shadow: 1px 1px 5px rgba($color: #000000, $alpha: 0.2);
        
		img {
			width: 100%;
		}
	}

	&#installIPhone {
		max-width: 450px;
		margin: 5px;
		position: fixed;
		bottom: -70px;
		width: calc(100% - 10px);
		z-index: 99999;
        transition: all 1s ease;
        
		&.show {
			bottom: 14px;
        }
        
		.arrow {
			position: absolute;
			left: calc(50% - 12px);
			bottom: 0;
			
            &:before,
		    &:after {
                border-width: 1rem 1rem 0;
            }
		}

		.tooltip-inner {
			max-width: 100%;
			box-shadow: 0px 0px 1px rgba($color: #000000, $alpha: 0.8);
			border: 0px;
			border-radius: 8px;
			background-color: rgba($color: #ffffff, $alpha: 1);
			display: flex;
			justify-content: space-around;
			align-items: middle;

			p {
				font-size: 0.8rem;
				text-align: left;
				margin: 0px;
				span {
					display: inline-block;
					margin: -1px 5px;
					width: 20px;
					height: 20px;
					background-image: url("./../img/content/icon-iphone-options.svg");
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
					vertical-align: bottom;
				}
			}

			&::before {
				content: "";
				display: inline-block;
				width: 45px;
				height: 40px;
				margin-right: 10px;
				margin-left: 5px;
				background-image: url("./../img/content/icon-iphone-add.svg");
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
			}
		}
		
        @media screen and (orientation:landscape) {
            bottom: auto;
            top: -70px;
            right: 5px;

            &.show {
                bottom: auto;
                top: 12px;
            }

            .arrow {
                transform: rotate(180deg);
                left: auto;
                right: 38px;
                bottom: auto;
                top: 0px;
            }

            .tooltip-inner {                
                p {
                    font-size: 0.9rem;
                }
                &:before {
                    width: 55px;
				    height: 45px;
                }
            }
		}
		
        &.ipad{
            bottom: auto;
            top: -70px;
            right: 5px;

            &.show {
                bottom: auto;
                top: 12px;
            }

            .arrow {
                transform: rotate(180deg);
                left: auto;
                right: 38px;
                bottom: auto;
                top: 0x;
            }

            .tooltip-inner {                
                p {
                    font-size: 0.9rem;
                }
                &:before {
                    width: 55px;
				    height: 45px;
                }
            }
        }
	}

	&.bs-tooltip-bottom {
		.arrow {
			transform: rotate(180deg);
		}
	}
}
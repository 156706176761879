.production {
    position: relative;
    padding: 3rem 0 0;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 580px;
        @include fillit(5,5,f,f,f);
        border-bottom-right-radius: 500px;
        background: $theme-color-gray-light;
        z-index: -1;

        @include media-breakpoint-up(lg){
            height: 480px;
        }
    }
}
body {
    font-size: 1rem;
    font-weight: 300;
    color: #0e0e0e;
	line-height: 1.65;
	font-family: $font-prime;
}

a,
a:hover {
    color: unset;
    text-decoration: none;
}

p {
    font-size: 1.1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
}

h1 {
    font-size: 2.2rem;
}

h2 {
    font-size: 1.9rem;
}
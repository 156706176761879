.accordion {

    .card {
        border: none;
        margin-bottom: 2px;

        &__header {
            padding: .75rem 1.25rem;
            text-align: center;
            background-color: $theme-color-gray-light;
            border-top-left-radius: 100px;
            border-bottom-right-radius: 100px;
            transition: background-color .3s ease-in-out;

            .btn {
                font-weight: 600;
                color: $theme-color-black;
                border: none;
                display: block;
                text-align: center;
                line-height: 1.1;
                width: 100%;
                background: none;
                
                @include media-breakpoint-up(md){
                    font-size: 1.5rem;
                }
            }

            &.active {
                background-color: $theme-color-black;
                
                .btn {
                    color: #fff;
                }
            }
        }

        &__body {
            padding: 2rem 1rem 1rem;
            text-align: center;
        }
    }
}
// Bootstrap
.form-control {
    border-radius: 0;
    border: 1px solid #A7A7A7;
}

.modal-content {
    background-color: #1b1b1b;
}

.modal-body {
    padding: .2rem;
}

// Wordpress
.alignleft, img.alignleft {
	margin-right: 1.5em;
    float: left;
    max-width: 100%;
    height: auto;
}

.alignright, img.alignright {
	margin-left: 1.5em;
    float: right;
    max-width: 100%;
    height: auto;
}

.aligncenter, img.aligncenter {
	margin-right: auto;
	margin-left: auto;
	display: block;
    clear: both;
    max-width: 100%;
    height: auto;
}

.alignnone, img.alignnone {
    max-width: 100%;
    height: auto;
}

// Lightbox 
.ekko-lightbox {
    .modal-content {
        background-color: transparent;
        border: none;
    }
}

// Contact Form
.wpcf7-not-valid-tip {
    font-size: 12px !important;
    text-align: center;
}

.wpcf7-response-output {
    font-size: 15px;
    text-align: center;
    flex: 0 0 90%;
    order: 12;
    margin: 1rem auto !important;
    border: none !important;
    border-radius: 1px;
    padding: .5rem 1rem !important;
    background-color: #ebebeb;
    
    @include media-breakpoint-up(lg){
        flex: 0 0 49%;
        margin-left: auto !important;
        margin-right: 0 !important;
    }
}

.wpcf7 form.invalid .wpcf7-response-output, .wpcf7 form.unaccepted .wpcf7-response-output {
    background-color: #de626d;
    color: #fff;
}

.wpcf7-acceptance {
    .wpcf7-list-item {
        font-size: 14px;
        margin-left: 0 !important;

        input {
            position: relative;
            width: 20px;
            height: 20px;
            position: relative;
            top: 3px;
            margin-right: .5rem;
            cursor: pointer;

            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background: #7b7b7b;
                border-radius: 1px;
                z-index: 9;
            }
            
            &::after {
                content: '\2713';
                position: absolute;
                text-align: center;
                line-height: 20px;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                color: #fff;
                z-index: 10;
                opacity: 0;
                transition: opacity .15s ease-in-out;
            }

            &:checked {
                &::after {
                    opacity: 1;
                }
            }
        }
    }
}

.grecaptcha-badge {
    display: none !important;
    z-index: -999 !important;
}